import { AsyncPipe } from '@angular/common';
import dayjs from 'dayjs';
import {
  Component, OnInit, Inject,
  DestroyRef
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { zip, combineLatest, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { Observable, concat } from 'rxjs';
import { MatAccordion, MatExpansionModule } from '@angular/material/expansion';
import { CartwheelButtonComponent, CartwheelIconButtonComponent } from '@cartwheel/web-components';
import { MatIconModule } from '@angular/material/icon';
import {
  State, getReportPageLoading, getLoadedClients,
  getProjectsList, getCompanyUsers,
  getReportPageCsvValues,
  getDashboardRole
} from 'app/redux/reducers';
import { Client } from 'app/models/Client';
import { Project } from 'app/models/project';
import { CompanyUser, CompanyUserPagedRequest } from 'app/models/CompanyUser';
import { take } from 'rxjs/operators';
import { ParseBulkTimeEntriesForUploadAction, SubmitBulktEntriesToServerAction } from 'app/redux/actions/timesheet';
import { BulkTeamTimeUpload } from 'app/models/bulkteamtimeupload';
import { TeamBulkImportColumns, SuccessStatus } from 'app/shared/enums';
import { fuzzyMatch } from 'app/shared/helpers';
import { GetCompanyUsersAction } from 'app/redux/actions/user';

@Component({
  standalone: true,
  selector: 'app-add-bulk-time',
  templateUrl: './add-bulk-time.component.html',
  styleUrls: ['./add-bulk-time.component.scss'],
  imports: [AsyncPipe, MatAccordion, MatExpansionModule, MatIconModule, CartwheelButtonComponent, CartwheelIconButtonComponent]
})
export class AddBulkTimeComponent implements OnInit {
  public loading$: Observable<SuccessStatus>;
  clients$: Observable<Client[]>;
  projects$: Observable<Project[]>;
  parsedCsv$: Observable<string[][]>;
  loadingStatus = SuccessStatus;

  bulkImportResults: BulkTeamTimeUpload[];

  private clients: Client[];
  private projects: Project[];
  private users: CompanyUser[] = [];
  private csvFile: File;
  public allValid = new Subject<boolean>();
  isLoading: SuccessStatus;
  constructor(
    public dialog: MatDialogRef<AddBulkTimeComponent>,
    @Inject(MAT_DIALOG_DATA) data: File,
    private destroyRef: DestroyRef,
    private store: Store<State>
  ) {
    this.loading$ = store.select(getReportPageLoading);
    this.csvFile = data;
  }

  ngOnInit() {
    this.clients$ = this.store.select(getLoadedClients);
    this.projects$ = this.store.select(getProjectsList);
    this.parsedCsv$ = this.store.select(getReportPageCsvValues);
    this.loading$ = this.store.select(getReportPageLoading);

    this.loading$.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe((res: SuccessStatus) => {
      this.isLoading = res;
      if (res === SuccessStatus.Success) {
        this.closeDialog();
      }
    })

    this.clients$.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(c => this.clients = c);

    this.projects$.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(p => this.projects = p);

    this.store.select(getDashboardRole)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.store.dispatch(GetCompanyUsersAction(new CompanyUserPagedRequest())));

    this.store.select(getCompanyUsers)
      .pipe(
        takeUntilDestroyed(this.destroyRef)
      ).subscribe(users => {
        this.users = [...this.users, ...users.data];
        if (users.totalPages > users.page) {
          this.store.dispatch(GetCompanyUsersAction({ page: users.page + 1 }));
        }
      });

    this.parsedCsv$.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe((res: string[][]) => {
      if (res) {
        this.bulkImportResults =
          this.generateBulkResultViewModel(res.slice(1), this.clients, this.projects, this.users);
      }
    });

    concat(
      zip(this.projects$, this.clients$)
        .pipe(take(1)),
      combineLatest([this.projects$, this.clients$])
    ).pipe(
      takeUntilDestroyed(this.destroyRef)
    )
      .subscribe(() => {
        this.store.dispatch(new ParseBulkTimeEntriesForUploadAction(this.csvFile))
      });
  }

  private generateBulkResultViewModel(values: string[][],
    clients: Client[], projects: Project[],
    users: CompanyUser[]): BulkTeamTimeUpload[] {

    const results: BulkTeamTimeUpload[] = new Array<BulkTeamTimeUpload>();
    values.forEach(e => {
      const result = new BulkTeamTimeUpload(e);
      result.valid = true;
      // check if client is valid and found

      const clientsExist = clients.find(f => fuzzyMatch(e[TeamBulkImportColumns.ClientName], f.clientName));
      if (!clientsExist) {
        result.errors.push(['Unable to find a client with that name', e[TeamBulkImportColumns.ClientName]]);
      } else {
        result.client = clientsExist;
      }

      // check if project is valid and found
      const projectExist = (
        result.client
          ? projects.filter(f => f.clientId === result.client.clientID)
          : projects
      ).find(f => fuzzyMatch(e[TeamBulkImportColumns.Project], f.projectName));

      if (!projectExist) {
        result.errors.push(['Unable to find a matching Project', e[TeamBulkImportColumns.Project]]);
      } else {
        result.project = projectExist
      }
      // check if user is valid and found
      const userExists = users.find(f => fuzzyMatch(e[TeamBulkImportColumns.Username], f.email));
      if (!userExists) {
        result.errors.push(['Unable to find matching user', e[TeamBulkImportColumns.Username]]);
      } else {
        result.user = userExists;
      }

      // check if start date is valid and found
      const selectedDate = dayjs(e[TeamBulkImportColumns.StartDate]);
      if (!selectedDate.isValid()) {
        result.errors.push(['Selected Date was invalid', e[TeamBulkImportColumns.StartDate]]);
      } else {
        result.startDate = new Date(selectedDate.toLocaleString());
      }

      // check if hours exist and are numbers
      const selectedHours = e[TeamBulkImportColumns.Hours]?.trim();
      const parsedHours = parseInt(selectedHours, 10);
      if (selectedHours === null || selectedHours === undefined || selectedHours.length === 0 || parsedHours <= 0) {
        result.errors.push(['Hours need to be populated and greater than zero', e[TeamBulkImportColumns.Hours]]);
      } else {
        result.hours = parsedHours;
      }

      // check if end date is valid and found
      if (result.errors.length > 0) {
        result.valid = false;
      }
      results.push(result);
    });
    if (results.map(e => e.errors).find(e => !!e.length)?.length > 0) {
      this.allValid.next(false);
    } else {
      this.allValid.next(true);
    }
    return results;
  }
  public onSubmit() {
    this.store.dispatch(new SubmitBulktEntriesToServerAction(this.bulkImportResults))
  }

  public closeDialog() {
    this.dialog.close();
  }

}
