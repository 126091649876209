import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { take } from 'rxjs/operators';
import { Component, DestroyRef, HostListener, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { UUID } from 'angular2-uuid';
import { EntryCreationLocation, TimeEntry } from '../../../models/timeentry';
import { EditTimeEntriesAction, SubmitTimeEntryAction } from '../../../redux/actions/timer';
import { Observable, merge } from 'rxjs';
import { Client } from '../../../models/Client';
import { Project } from '../../../models/project';
import { OpenClientAddDialogAction, SelectClientAction } from '../../../redux/actions/client';
import { AddNewProject, SelectProjectAction } from '../../../redux/actions/project';
import {
  getLoadedClients,
  getProjectCompleted,
  getProjectLoading,
  getProjectSelectedSingle,
  getProjectsList,
  getSelectedClient,
  getTimerLoading,
  State,
  getDashboardRole
} from '../../../redux/reducers/index';
import { Status, SuccessStatus, ClientStatus, UserRole } from '../../enums';
import roundDuration, { getRoundingRule } from '../../../../business_logic/rounding';
import {
  CartwheelDatepickerComponent, CartwheelIconButtonComponent, CartwheelInputComponent, CartwheelSelectComponent,
  CartwheelSelectOptions, UserCompanyRole, CartwheelButtonComponent
} from '@cartwheel/web-components';
import { AsyncPipe } from '@angular/common';
import { environment } from 'environments/environment';
import { formatDuration } from 'app/clients/client-shared-functions';
import { PATTERNS } from 'app/shared/consts';
import { PipesModule } from 'app/pipes/pipes.module';

// dayjs plugins
dayjs.extend(localizedFormat);

@Component({
  standalone: true,
  selector: 'app-add-time',
  templateUrl: './add-time.component.html',
  styleUrls: ['./add-time.component.scss'],
  imports: [
    AsyncPipe, ReactiveFormsModule, CartwheelButtonComponent, CartwheelDatepickerComponent,
    CartwheelIconButtonComponent, CartwheelInputComponent, CartwheelSelectComponent, PipesModule
  ]
})
export class AddTimeComponent implements OnInit {
  projectsLoading$: Observable<boolean | SuccessStatus>;
  clients$: Observable<Client[]>;
  selectedClient$: Observable<Client>;
  projects$: Observable<Project[]>;
  selectedProject$: Observable<Project>;
  projectSaveComplete$: Observable<boolean>;
  currentRole$: Observable<UserCompanyRole>;
  loadingProject: boolean | SuccessStatus;
  submitTimeEntry: UntypedFormGroup;
  clients: Client[];
  selectedProject: Project;
  unfilteredProjects: Project[] = [];
  filteredProjects: Project[] = [];
  times: string[] = [];
  hours: string[] = [];
  minutes: string[] = [];
  meridiem: string[] = ['AM', 'PM'];
  durationDays: string = '-';
  durationHours: string = '-';
  durationMinutes: string = '-';
  selectedClient: Client;
  dateTimeValidationError: string = null;
  UserRole = UserRole;
  projectName = '';
  minDate = dayjs()
    .subtract(10, 'years')
    .toDate();
  maxDate = dayjs()
    .add(10, 'years')
    .toDate();
  submitting$: Observable<boolean>;
  submitting: boolean;
  public editableTimeEntry: TimeEntry;
  durationFormat = 'D:HH:mm';
  timeslotFormat = 'hh:mm:ssa';
  dateFormat = 'DD/MM/YYYY';
  public clientsOptions: CartwheelSelectOptions<Client> = [];
  public filteredProjectOptions: CartwheelSelectOptions<Project> = [];
  public assignedUsersOptions: CartwheelSelectOptions<string> = [];
  public hoursOptions: CartwheelSelectOptions<string> = [];
  public minutesOptions: CartwheelSelectOptions<string> = [];
  public meridiemOptions: CartwheelSelectOptions<string> = this.meridiem.map(m => ({ label: m, value: m }));
  public successStatus = SuccessStatus;

  constructor(
    private dialog: MatDialogRef<AddTimeComponent>,
    private fb: UntypedFormBuilder,
    private store: Store<State>,
    private destroyRef: DestroyRef,
    @Inject(MAT_DIALOG_DATA) data: TimeEntry
  ) {
    this.editableTimeEntry = data;
  }

  ngOnInit() {
    this.generateTimeslots();
    this.selectedProject = new Project();
    this.selectedProject$ = this.store.select(getProjectSelectedSingle);
    this.projectSaveComplete$ = this.store.select(getProjectCompleted);
    this.clients$ = this.store.select(getLoadedClients);
    this.projectsLoading$ = this.store.select(getProjectLoading);
    this.projects$ = this.store.select(getProjectsList);
    this.selectedClient$ = this.store.select(getSelectedClient);
    this.currentRole$ = this.store.select(getDashboardRole);

    this.selectedProject$.pipe(
      takeUntilDestroyed(this.destroyRef))
      .subscribe(proj => {
        if (proj && this.selectedClient) {
          this.selectedProject = proj;
        } else {
          this.selectedProject = new Project();
        }
      });

    this.clients$.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(clients => {
        this.clients = clients.filter(c => c.status !== ClientStatus.Deleted && c.status !== ClientStatus.Archived);
        this.clientsOptions = this.clients.map(c => ({
          label: c.clientName,
          value: c
        }));
      });

    // this.projectSaveComplete$.pipe(
    //   takeUntilDestroyed(this.destroyRef))
    //   .subscribe(completed => {
    //     if (completed) {
    //       this.dropdownComponent.toggleDropdown();
    //     }
    //   });

    this.projectsLoading$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(loading => (this.loadingProject = loading));

    this.projects$.pipe(
      takeUntilDestroyed(this.destroyRef))
      .subscribe(projs => {
        this.unfilteredProjects = projs;
        if (this.selectedClient) {
          this.filteredProjects = projs.filter(proj => {
            return proj.clientId === this.selectedClient.clientID;
          });
          this.filteredProjectOptions = this.filteredProjects.map(p => ({
            label: p.projectName,
            value: p
          }));
        }
      });

    this.selectedClient$.pipe(
      takeUntilDestroyed(this.destroyRef))
      .subscribe(client => {
        if (client) {
          this.selectedClient = client;
          this.filteredProjects = this.unfilteredProjects.filter(
            proj => proj.clientId === this.selectedClient.clientID
          );
          this.filteredProjectOptions = this.filteredProjects.map(p => ({
            label: p.projectName,
            value: p
          }));
          this.assignedUsersOptions = this.selectedClient?.assignedUsers?.map(u => ({
            label: u.username,
            value: u.userId
          }));

          if (this.submitTimeEntry) {
            this.submitTimeEntry.get('selectedClient').setValue(this.selectedClient);
          }
        }
      });

    this.submitting$ = this.store.select(getTimerLoading);
    this.submitting$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(submitting => (this.submitting = submitting));
    const initialValues = this.getInitialFormValues(this.editableTimeEntry);

    this.currentRole$.pipe(take(1)).subscribe(cr => {
      this.submitTimeEntry = this.initialiseForm(initialValues, cr);

      this.submitTimeEntry.get('selectedClient').valueChanges.subscribe(client => {
        this.store.dispatch(new SelectProjectAction(undefined));
        this.store.dispatch(new SelectClientAction(client));
      });

      this.submitTimeEntry.get('projectName').valueChanges.subscribe(project => {
        if (project && project.clientId && project.clientId.length > 0) {
          this.store.dispatch(new SelectProjectAction(project));
        }
      });

      merge(
        this.submitTimeEntry.get('selectedStartDate').valueChanges,
        this.submitTimeEntry.get('selectedEndDate').valueChanges,
        this.submitTimeEntry.get('selectedStartHour').valueChanges,
        this.submitTimeEntry.get('selectedStartMinute').valueChanges,
        this.submitTimeEntry.get('selectedStartMeridiam').valueChanges,
        this.submitTimeEntry.get('selectedEndHour').valueChanges,
        this.submitTimeEntry.get('selectedEndMinute').valueChanges,
        this.submitTimeEntry.get('selectedEndMeridiam').valueChanges
      )
        .subscribe(() => {
          this.changedDateOrTime();
        });
    });
  }

  private getInitialFormValues(data) {
    let initialValues = {
      selectedClient: null,
      projectName: null,
      description: '',
      selectedStartDate: null,
      selectedEndDate: null,
      selectedStartHour: null,
      selectedStartMinute: null,
      selectedStartMeridiam: null,
      selectedEndHour: null,
      selectedEndMinute: null,
      selectedEndMeridiam: null,
      duration: ''
    };
    if (!data) {
      return initialValues;
    }

    let selectedClient;
    selectedClient = this.clients.find(c => c.clientID === data.clientId);
    this.selectedClient = selectedClient;

    // this.filteredProjectOptions = [];
    // this.assignedUsersOptions = [];

    this.selectedProject = this.getProject(data);
    const roundingType = getRoundingRule(this.clients, selectedClient);
    const duration = formatDuration(roundDuration(dayjs(data.startTime), dayjs(data.endTime), roundingType), {
      format: this.durationFormat,
    });

    const durationDays = '-';
    const durationHours = '-';
    const durationMinutes = '';
    const startTimeMoment = dayjs(data.startTime);
    const endTimeMoment = dayjs(data.endTime);
    let startHour = '1';
    let startMinute = '0';
    let startMeridiam = 'am';
    let endHour = '1';
    let endMinute = '0';
    let endMeridiam = 'am';
    if (startTimeMoment) {
      const startTimeSplit = startTimeMoment.format('LT').split(' ')
      startMeridiam = startTimeSplit[1];
      const startTimeString = startTimeSplit[0].split(':');
      startHour = startTimeString[0];
      startMinute = startTimeString[1];
    }
    if (endTimeMoment) {
      const endTimeSplit = endTimeMoment.format('LT').split(' ')
      endMeridiam = endTimeSplit[1];
      const endTimeString = endTimeSplit[0].split(':');
      endHour = endTimeString[0];
      endMinute = endTimeString[1];
    }
    if (duration) {
      const durationSplit = duration.toString().split(':');
      this.durationDays = durationSplit[0];
      this.durationHours = durationSplit[1];
      this.durationMinutes = durationSplit[2];
    }

    initialValues = {
      selectedClient,
      projectName: this.selectedProject,
      description: data.description,
      selectedStartDate: startTimeMoment.toDate(),
      selectedEndDate: endTimeMoment.toDate(),
      selectedStartHour: startHour,
      selectedStartMinute: startMinute,
      selectedStartMeridiam: startMeridiam,
      selectedEndHour: endHour,
      selectedEndMinute: endMinute,
      selectedEndMeridiam: endMeridiam,
      duration
    };

    return initialValues;
  }

  private initialiseForm(initialValues, role: UserCompanyRole) {
    const formValues = {
      selectedClient: [initialValues.selectedClient, [Validators.required, this.clientIsNotPlaceholderValidator()]],
      projectName: [initialValues.projectName, [Validators.required]],
      description: [initialValues.description, [Validators.required]],
      selectedStartDate: [initialValues.selectedStartDate, [Validators.required]],
      selectedEndDate: [initialValues.selectedEndDate, [Validators.required]],
      selectedStartHour: [initialValues.selectedStartHour, [Validators.required]],
      selectedStartMinute: [initialValues.selectedStartMinute, [Validators.required]],
      selectedStartMeridiam: [initialValues.selectedStartMeridiam, [Validators.required]],
      selectedEndHour: [initialValues.selectedEndHour, [Validators.required]],
      selectedEndMinute: [initialValues.selectedEndMinute, [Validators.required]],
      selectedEndMeridiam: [initialValues.selectedEndMeridiam, [Validators.required]],
      duration: new UntypedFormControl(
        {
          value: initialValues.duration,
          disabled: true
        },
        Validators.required
      )
    };

    if (role.userRole !== UserRole.User) {
      return this.fb.group({ ...formValues, userId: [null, [Validators.required]] });
    }

    return this.fb.group({ ...formValues, userId: [role.userId, [Validators.required]] });
  }

  public onSubmit() {
    if (this.submitTimeEntry.valid) {
      const newTimeEntry = this.getFormTimeEntry(this.editableTimeEntry);
      if (this.editableTimeEntry) {
        this.store.dispatch(new EditTimeEntriesAction([newTimeEntry]));
      } else {
        this.store.dispatch(new SubmitTimeEntryAction(newTimeEntry));
      }
    }
  }

  public getFormTimeEntry(existingTimeEntry?: TimeEntry): TimeEntry {
    if (existingTimeEntry) {
      const newEntry = new TimeEntry(existingTimeEntry);
      newEntry.startTime = this.getStartTimestamp().toDate();
      newEntry.endTime = this.getEndTimestamp().toDate();
      newEntry.projectId = this.selectedProject.projectId;
      newEntry.clientId = this.selectedClient.clientID;
      newEntry.createdByUserId = this.submitTimeEntry.get('userId').value;
      newEntry.description = this.submitTimeEntry.get('description').value;
      newEntry.creationLocation = EntryCreationLocation.AddTimeModal;
      if (!environment.production)
        console.log(newEntry);
      return newEntry;
    } else {
      const newEntry = new TimeEntry({
        startTime: this.getStartTimestamp().toDate(),
        endTime: this.getEndTimestamp().toDate(),
        projectId: this.selectedProject.projectId,
        clientId: this.selectedClient.clientID,
        createdByUserId: this.submitTimeEntry.get('userId').value,
        description: this.submitTimeEntry.get('description').value,
        status: Status.Active,
        creationLocation: EntryCreationLocation.AddTimeModal
      });
      return newEntry;
    }
  }

  public generateTimeslots(): void {
    const newHours = [];
    const newMinutes = [];

    for (let i = 1; i <= 12; i++) {
      let hour = i.toString();
      if (i <= 9) {
        hour = '0' + i;
      }
      newHours.push(hour);
    }

    for (let i = 0; i < 60; i++) {
      let minute = i.toString();
      if (i <= 9) {
        minute = '0' + i;
      }
      newMinutes.push(minute);
    }
    this.hours = newHours;
    this.minutes = newMinutes;

    this.hoursOptions = newHours.map(hour => ({ value: hour, label: hour }));
    this.minutesOptions = newMinutes.map(minute => ({ value: minute, label: minute }));
  }

  public timeslotValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const hasRightFormat = PATTERNS.TimeSlot.test(control.value);
      return hasRightFormat ? null : { timeslotWrongFormat: { value: control.value } };
    };
  }

  public negativeDurationValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (this.submitTimeEntry) {
        const startDate =
          dayjs(this.submitTimeEntry.controls['selectedStartDate'].value).format(this.dateFormat);
        const endDate =
          dayjs(this.submitTimeEntry.controls['selectedEndDate'].value).format(this.dateFormat);
        const startTimeString = this.getStartTimeString();
        const endTimeString = this.getEndTimeString();

        const startDateTime = dayjs(startTimeString, 'yyyy-MM-DD LT');
        const endDateTime = dayjs(endTimeString, 'yyyy-MM-DD LT');

        if (startDate && endDate && startDateTime && endDateTime) {
          const duration = dayjs.duration(endDateTime.diff(startDateTime)).asSeconds();
          return duration > 0 ? null : { negativeDuration: { duration } };
        }
        return null;
      }
      return null;
    };
  }

  public getStartTimeString() {
    return `${dayjs(this.submitTimeEntry.controls['selectedStartDate'].value).format('YYYY-MM-DD')} ${this.submitTimeEntry.controls['selectedStartHour'].value ?? '00'
      }:${this.submitTimeEntry.controls['selectedStartMinute'].value ?? '00'
      } ${this.submitTimeEntry.controls['selectedStartMeridiam'].value ?? '00'
      }`;
  }

  public getEndTimeString() {
    return `${dayjs(this.submitTimeEntry.controls['selectedEndDate'].value).format('YYYY-MM-DD')} ${this.submitTimeEntry.controls['selectedEndHour'].value ?? '00'
      }:${this.submitTimeEntry.controls['selectedEndMinute'].value ?? '00'
      } ${this.submitTimeEntry.controls['selectedEndMeridiam'].value ?? '00'
      }`;
  }

  public clientIsNotPlaceholderValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (this.submitTimeEntry) {
        const placeholderValue = 'undefined';
        return control.value !== placeholderValue ? null : { clientIsPlaceholder: { placeholder: control.value } };
      }
      return null;
    };
  }

  public changedDateOrTime() {
    this.getTimeValidation();
    this.generateTimeslots();
    this.updateDurationField();
  }

  public updateDurationField() {
    const startDateTimestamp = this.getStartTimestamp();
    const endDateTimestamp = this.getEndTimestamp();
    if (startDateTimestamp && endDateTimestamp) {
      const roundingType = getRoundingRule(this.clients, this.selectedClient);
      const duration = formatDuration(roundDuration(startDateTimestamp, endDateTimestamp, roundingType), {
        format: this.durationFormat
      });
      const durationTokens = duration.split(':');
      this.submitTimeEntry.controls['duration'].setValue(duration);
      this.durationDays = durationTokens[0];
      this.durationHours = durationTokens[1];
      this.durationMinutes = durationTokens[2];
      this.submitTimeEntry.controls['duration'].setValue(duration);
    } else {
      this.submitTimeEntry.controls['duration'].setValue('');
    }
  }

  public getTimeValidation() {
    const startDateTimestamp = this.getStartTimestamp();
    const endDateTimestamp = this.getEndTimestamp();

    if ((endDateTimestamp === null || endDateTimestamp === undefined) ||
      startDateTimestamp === null || startDateTimestamp === undefined) {
      this.dateTimeValidationError = 'Please ensure both start and end times are populated';
      return;
    }

    if (!startDateTimestamp.isValid()) {
      this.dateTimeValidationError = 'Please enter a valid start time';
    } else if (!endDateTimestamp.isValid()) {
      this.dateTimeValidationError = 'Please enter a valid end time';
    } else if (endDateTimestamp.diff(startDateTimestamp) < 0) {
      this.dateTimeValidationError = 'End date-time should be greater than start date-time';
    } else {
      this.dateTimeValidationError = null;
    }
  }

  public getStartTimestamp(): dayjs.Dayjs {
    const startDateField = this.submitTimeEntry.get('selectedStartDate');

    const startTimeString = this.getStartTimeString();
    if (startDateField.valid) {
      const startTimestamp = dayjs(startTimeString, `yyyy-MM-DD LT`);
      return startTimestamp;
    } else {
      return;
    }
  }

  public getEndTimestamp(): dayjs.Dayjs {
    const endDateField = this.submitTimeEntry.get('selectedEndDate');
    const endTimeString = this.getEndTimeString();

    if (endDateField.valid) {
      const endTimestamp = dayjs(endTimeString, `${this.dateFormat} LT`);
      return endTimestamp;
    } else {
      return;
    }
  }

  public addClient() {
    this.store.dispatch(new SelectProjectAction(undefined));
    this.store.dispatch(new OpenClientAddDialogAction());
  }

  public selectProject(project: Project) {
    if (project && project.clientId && project.clientId.length > 0) {
      this.store.dispatch(new SelectProjectAction(project));
      this.submitTimeEntry.controls['projectName'].setValue(project);
    }
  }

  public getProject(selectedEntry: TimeEntry): Project {
    if (selectedEntry && selectedEntry.clientId.length > 0) {
      return this.unfilteredProjects.find(s => s.projectId === selectedEntry.projectId);
    }
  }

  public addNewProject(projectName: string, event?: any) {
    if (event?.stopPropagation) {
      event.preventDefault();
    }
    if (event?.cancelBubble) {
      event.cancelBubble = true;
    }
    const existingProject = this.unfilteredProjects.find(s => s.projectName === projectName);
    if (existingProject) {
      this.selectProject(existingProject);
      return;
    }
    const newProject = new Project();
    newProject.projectId = UUID.UUID();
    newProject.clientId = this.selectedClient.clientID;
    newProject.projectName = projectName;
    newProject.status = Status.Active;
    newProject.creationDateTime = dayjs().toDate();
    this.store.dispatch(new AddNewProject(newProject));
    const subscription = this.projectsLoading$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(loading => {
        if (loading === SuccessStatus.Success)
          this.submitTimeEntry.controls['projectName'].setValue(newProject);
        if (loading === false || loading !== SuccessStatus.IsLoading) subscription.unsubscribe();
      })
  }

  @HostListener('keydown.esc')
  public onEsc(): void {
    this.dialog.close();
  }

  public closeDialog() {
    this.dialog.close();
  }

  public minMaxDateFilter = (d: Date | null): boolean => {
    return d >= this.minDate && d <= this.maxDate;
  };

  public endDateMinMaxDateFilter = (d: Date | null): boolean => {
    return d >= (this.submitTimeEntry.get('selectedStartDate').value || this.minDate) && d <= this.maxDate;
  };
}
